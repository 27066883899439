import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";

import { setCountry } from "../../store/languageSlice/languageSlice";

import logoDark from "../../images/logo-dark.svg";
import logoLight from "../../images/logo-light.svg";

import alFlag from '../../countries-icons/al.svg'
import atFlag from '../../countries-icons/at.svg'
import beFlag from '../../countries-icons/be.svg'
import bgFlag from '../../countries-icons/bg.svg'
import chFlag from '../../countries-icons/ch.svg'
import cyFlag from '../../countries-icons/cy.svg'
import czFlag from '../../countries-icons/cz.svg'
import deFlag from '../../countries-icons/de.svg'
import esFlag from '../../countries-icons/es.svg'
import euFlag from '../../countries-icons/eu.svg'
import frFlag from '../../countries-icons/fr.svg'
import gbFlag from '../../countries-icons/gb.svg'
import grFlag from '../../countries-icons/gr.svg'
import hrFlag from '../../countries-icons/hr.svg'
import huFlag from '../../countries-icons/hu.svg'
import itFlag from '../../countries-icons/it.svg'
import luFlag from '../../countries-icons/lu.svg'
import mcFlag from '../../countries-icons/mc.svg'
import mtFlag from '../../countries-icons/mt.svg'
import nlFlag from '../../countries-icons/nl.svg'
import plFlag from '../../countries-icons/pl.svg'
import ptFlag from '../../countries-icons/pt.svg'
import roFlag from '../../countries-icons/ro.svg'
import rsFlag from '../../countries-icons/rs.svg'
import skFlag from '../../countries-icons/sk.svg'
import trFlag from '../../countries-icons/tr.svg'

import styles from "./footer.module.scss";

const countryImages = {
  albania: alFlag,
  austria: atFlag,
  belgium: beFlag,
  bulgaria: bgFlag,
  switzerland: chFlag,
  cyprus: cyFlag,
  czechia: czFlag,
  germany: deFlag,
  spain: esFlag,
  europe: euFlag,
  france: frFlag,
  "great-britain": gbFlag,
  greece: grFlag,
  croatia: hrFlag,
  hungary: huFlag,
  italy: itFlag,
  luxembourg: luFlag,
  monaco: mcFlag,
  malta: mtFlag,
  netherlands: nlFlag,
  poland: plFlag,
  portugal: ptFlag,
  romania: roFlag,
  serbia: rsFlag,
  slovakia: skFlag,
  turkiye: trFlag,
}

const Footer = ({ countriesInfo, addAgencyRef }) => {
  const dispatch = useDispatch()
  const { lang, country } = useParams();
  const { theme } = useSelector((state) => state.user);
  const { mainWords, selectedLanguage, selectedCountry } = useSelector(
    (state) => state.language
  );

  const { bycountry, ads, meth, add, miniLogoTitle, miniLogoAlt } = mainWords;

  const selectCountry = (countryName) => {
    dispatch(setCountry(countryName))
  }

  const toMeth = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const inCountry = countriesInfo.filter((el) => {
    let key = Object.keys(el)[0];
    if (!!country) {
      if (key === country) {
        return el;
      }
    } else {
      if (key === "europe") {
        return el;
      }
    }
  })[0][!!country ? country : "europe"];

  const toAddAgency = () => {
    addAgencyRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <footer>
      <div className={styles.footer__container}>
        <div className={clsx(styles.top, "flex-between")}>
          <Link to={`${lang}/${country ? country : 'europe'}`} title={`${miniLogoTitle} ${inCountry[1]} - EroTop`}>
            <img
              className={styles.logo}
              src={theme === "light" ? logoLight : logoDark}
              alt={`${miniLogoAlt} ${inCountry[1]}`}
            />
          </Link>
          <div className={styles.countries__wrapper}>
            <p className={styles.block__title}>{bycountry}</p>
            <div className={styles.countries}>
              {countriesInfo.map((el, i) => {
                let key = Object.keys(el)[0]
                return (
                  <Link
                    to={`/${selectedLanguage}/${key}`}
                    key={i}
                    title={el[key][0]}
                    className={clsx(styles.country, key === country ? styles.checked : "")}
                    onClick={() => selectCountry(key)}
                  >
                    <img src={countryImages[key]} alt={el[key][0]} />
                    <div>{(el[key][2]).toUpperCase()}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className={clsx(styles.bottom, "flex-between")}>
          <div className={clsx(styles.contacts, "flex-between")}>
            <p>© 2023 EroTop</p>
            <a href="mailto:info@erotop10.com">info@erotop10.com</a>
          </div>
          <div className={clsx(styles.links, "flex-between")}>
            <Link to={`/${selectedLanguage}/meth#adInPortal`} className={styles.link}>
              {ads}
            </Link>
            <Link to={`/${selectedLanguage}/meth`} className={styles.link} onClick={toMeth}>
              {meth}
            </Link>
            <Link to={`/${selectedLanguage}/${selectedCountry}#addAgency`} className={styles.button} onClick={toAddAgency}>
              {add}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
