import { useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react';

import TopWebsites from '../top-websites/TopWebsites'
import TopAgencies from '../top-agencies/TopAgencies'

import styles from './toggle-blocks.module.scss'
import 'swiper/css';

const ToggleBlocks = ({ countriesInfo }) => {
    const { mainWords, selectedLanguage } = useSelector(state => state.language)

    const { h21mobile, h22mobile } = mainWords

    const [checked, setChecked] = useState("sites")

    const [swiper, setSwiper] = useState(null);

    const slideNext = () => {
        swiper.slideNext()
        setChecked("agencies")
    }
    
    const slidePrev = () => {
        swiper.slidePrev()
        setChecked("sites")
    }

    return <div className={clsx(styles.toggle__blocks, "flex-column")}>
        <div className={clsx(styles.buttons, checked === "agencies" && styles.toggled)}>
            <button className={checked === "sites" ? styles.active : ""} onClick={slidePrev}>{h21mobile}</button>
            <button className={checked === "agencies" ? styles.active : ""} onClick={slideNext}>{h22mobile}</button>
        </div>
        <Swiper
            navigation
            spaceBetween={0}
            slidesPerView={1}
            onSwiper={(s) => {
                setSwiper(s);
            }}
        >
            <SwiperSlide>
                <TopWebsites />
            </SwiperSlide>
            <SwiperSlide>
                <TopAgencies countriesInfo={countriesInfo} />
            </SwiperSlide>
        </Swiper>
        {/* {checked === "sites" && <TopWebsites />}
        {checked === "agencies" && <TopAgencies />} */}
    </div>
}

export default ToggleBlocks