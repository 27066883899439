import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { setLanguage } from '../../store/languageSlice/languageSlice'

import { getLanguages } from '../../api/requests'

import styles from './language-dropdown.module.scss'
import { useParams } from 'react-router-dom'

// const languages = ["en", "ru", "cz", "de", "es"]

const LanguageDropdown = () => {

  const { selectedLanguage, selectedCountry, pathnameCountry } = useSelector(state => state.language)
  const [toggleDropdown, setToggleDropdown] = useState(null)
  const dispatch = useDispatch()
  const { lang, country } = useParams()

  const [languages, setLanguages] = useState([])

  useEffect(() => {
    getLanguages(lang).then(res => setLanguages(res.data.data))
  }, [])

  const dropdown = () => {
    setToggleDropdown(!toggleDropdown)
  }

  const changeLanguage = (language) => {
    dispatch(setLanguage(language))
    dropdown()
  }

  return (
    <div className={styles.lang__dropdown}>
      <div className={styles.select} onClick={() => setToggleDropdown(!toggleDropdown)}>{selectedLanguage.toUpperCase()}</div>
      <div className={clsx(styles.options, "flex-column", !toggleDropdown && styles.shown)}>
        {languages.map((language, i) => language.toUpperCase() !== selectedLanguage.toUpperCase() && <button key={i} onClick={() => changeLanguage(language)}>{language.toUpperCase()}</button>)}
      </div>
    </div>
  )
}

export default LanguageDropdown