import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'

import { setSentPopupIsOpen } from '../../store/reviewSlice/reviewSlice'
import { addAgencyRequest } from '../../api/requests'

import logo from '../../images/logo-dark.svg'

import styles from './add-agency.module.scss'

const AddAgency = ({ countriesInfo, addAgencyRef }) => {

    const dispatch = useDispatch()

    const { lang, country } = useParams()

    const { mainWords, selectedLanguage, selectedCountry } = useSelector(state => state.language)

    const { yoursite, youremail, addagency, formAlt } = mainWords

    const [formValues, setFormValues] = useState({
        agencyName: "",
        email: ""
    })

    const onChangeForm = (input) => {
        setFormValues({
            ...formValues,
            [input.target.name]: input.target.value
        })
    }

    const addAgencyRequestFunc = () => {
        addAgencyRequest(lang, "", formValues.email, formValues.agencyName, "").then(res => {
            if (res.status === 200) {
                dispatch(setSentPopupIsOpen(mainWords.dispatcMessage))
                document.body.style.overflow = "hidden"
                setTimeout(() => {
                    dispatch(setSentPopupIsOpen(""))
                    document.body.style.overflow = "visible"
                }, 1000)
                setFormValues({
                    agencyName: "",
                    email: "",
                })
            }
        })
    }

    const inCountry = countriesInfo.filter(el => {
        let key = Object.keys(el)[0]
        if (!!country) {
            if (key === country) {
                return el
            }
        } else {
            if (key === "eu") {
                return el
            }
        }
    })[0][!!country ? country : "eu"]

    return <div ref={addAgencyRef} id="addAgency" className={clsx(styles.wrapper, "flex-column")}>
        <img src={logo} alt={`${formAlt} ${inCountry[1]}`} />
        <p>{mainWords?.add2}</p>
        <form onSubmit={(e) => {
            e.preventDefault()
            addAgencyRequestFunc()
        }}>
            <input value={formValues.agencyName} type='text' name='agencyName' placeholder={yoursite} onChange={onChangeForm} required />
            <input value={formValues.email} type='text' name='email' placeholder={youremail} onChange={onChangeForm} required />
            <button type='submit'>{addagency}</button>
        </form>
    </div>
}

export default AddAgency