import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    selectedLanguage: "",
    selectedCountry: sessionStorage.getItem("country") ? sessionStorage.getItem("country") : "europe",
    mainWords: {},
    countryInfo: [],
  },
  reducers: {
    setLanguage: (state, action) => {
      if (state.selectedLanguage === action.payload) {
        return
      } else {
        state.selectedLanguage = action.payload
        Cookies.set('lang', action.payload);
      }
    },
    setCountry: (state, action) => {
      // state.selectedCountry = action.payload;
      if (state.selectedCountry === action.payload) {
        return
      } else {
        state.selectedCountry = action.payload
        sessionStorage.setItem('country', action.payload);
      }
    },
    setMainWords: (state, action) => {
      state.mainWords = action.payload;
    },
    setCountryInfo: (state, action) => {
      state.countryInfo = action.payload
    }
  },
});

export const { setLanguage, setCountry, setMainWords, setPathnameCountry, setCountryInfo } = languageSlice.actions;
export default languageSlice.reducer;