import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import RatingStars from '../rating-stars/RatingStars'
import clsx from 'clsx'

import { setPopupInfo } from '../../store/reviewSlice/reviewSlice'
import { getWebsites } from '../../api/requests'

import AddAgency from '../add-agency/AddAgency'
import ProstitutionInfo from '../prostitution-info/ProstitutionInfo'
import ProstitutionInfoTop from '../prostitution-info-top/ProstitutionInfoTop'
import Map from '../map/Map'
import ProstitutionInfoBottom from '../prostitution-info-bottom/ProstitutionInfoBottom'
import Loading from '../loading/Loading'

import styles from './top-websites.module.scss'

const TopWebsites = ({ showFormMobile, prostitutionInfo, loaded, countriesInfo, addAgencyRef, prostitutionInfoEu, inCountry }) => {
    const dispatch = useDispatch()
    const { lang, country } = useParams()
    const { pathname } = useLocation()

    const { mainWords, selectedLanguage, selectedCountry } = useSelector(state => state.language)

    const { h21, view, reviews, mores, addreview, traffic } = mainWords

    const [websites, setWebsites] = useState([])
    const [add, setAdd] = useState([])
    const [countPage, setCountPage] = useState(1)
    const [page, setPage] = useState(1)

    const [loadedWebsites, setLoadedWebsites] = useState(null)

    const getWebsitesFunc = (countryChanged) => {
        if (!!lang && !!country && page <= countPage) {
            getWebsites(lang, country, page).then(res => {
                if (res.status === 200) {
                    if (countryChanged) {
                        setCountPage(Math.ceil(res.data.count / 10))
                        setAdd(res.data.ad)
                        setWebsites(res.data.data)
                    } else {
                        setWebsites([...websites, ...res.data.data])
                    }
                    setTimeout(() => {
                        setLoadedWebsites(true)
                    }, 700)
                }
            })
        }
    }

    useEffect(() => {
        if (loadedWebsites) {
            if (window.location.href.includes("#addAgency")) {
                addAgencyRef.current?.scrollIntoView({ behavior: 'smooth' })
            } else if (pathname.includes("#adInPortal")) {
                // window.scrollTo({
                //     top: document.querySelector("#adInPortal").getBoundingClientRect().top,
                //     behavior: "smooth",
                // });
                // if (window.location.href.includes("#addAgency")) {
                //     window.scrollTo({
                //         top: addAgencyRef.getBoundingClientRect().top,
                //         behavior: "smooth",
                //     });
                // }
            }
        }
    }, [loadedWebsites])

    useEffect(() => {
        getWebsitesFunc()
    }, [])

    useEffect(() => {
        setLoadedWebsites(false)
        setPage(1)
        setWebsites([])
        if (page <= countPage) {
            getWebsitesFunc(true)
        }
    }, [lang, selectedCountry])

    useEffect(() => {
        // setLoadedWebsites(false)
        if (page <= countPage) {
            getWebsitesFunc()
        }
        // if (websites.length > 0) {
        //     setTimeout(() => {
        //         setLoadedWebsites(true)
        //     }, 700)
        // }
    }, [page])

    const showMore = () => setPage((count) => count += 1);

    const currentCountry = typeof countriesInfo === Object  ? countriesInfo.filter(el => {
        let key = Object.keys(el)[0]
        if (!!country) {
            if (key === country) {
                return el
            }
        } else {
            if (key === "europe") {
                return el
            }
        }
    })[0][!!country ? country : "europe"] : 'europe';

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    const replacedH21 = h21.replace(/%([^%]+)%/g, (match, p1) => {
        switch (p1) {
            case 'City':
                return currentCountry[1];
            case 'Month':
                return mainWords.month[currentMonthIndex];
            default:
                return match;
        }
    });

    return <div className={styles.wrapper}>
        <div className={clsx(styles.container, "flex-column")}>
            {/* <h2>{h21}</h2> */}
            <h2>{replacedH21}</h2>
            <div className={styles.websites}>
                {loadedWebsites && !!add.length ? (
                    <div className={clsx(styles.website, "flex-between")}>
                    <span className={clsx(styles.rate, add[0]?.position_diff > 0 ? styles.green : styles.red)} data-rate={add[0]?.position_diff !== 0 ? (add[0]?.position_diff) : ""}>{""}</span>
                    <div className={clsx(styles.info, "flex-between")}>
                        <div className={styles.main}>
                            <div className={clsx(styles.name, "flex-between")}>
                                {!!(add[0]?.favicon) && <img src={add[0]?.favicon} />}
                                <a href={"https://" + add[0]?.host} target='_blank'>{add[0]?.title}</a>
                            </div>
                            <p>{add[0]?.description}</p>
                            <div className={clsx("flex-between")}>
                                <div className={clsx(styles.local__link, "flex-between")}>

                                    {add[0]?.is_local && <div className={styles.local}>Local</div>}
                                    {add[0]?.host && <a className={styles.link} href={"https://" + add[0]?.host} target='_blank'>
                                        {add[0]?.host}
                                    </a>}
                                </div>
                                {/* <div className={styles.traffic}><span>{traffic} {inCountry}:</span> {add[0]?.traffic !== null && add[0]?.traffic.toLocaleString()}</div> */}
                            </div>
                        </div>
                        <div className={styles.views} data-toolbar={view} onClick={() => dispatch(setPopupInfo({ type: "site", id: add[0]?.id, item: add[0] }))}>
                            <div className={styles.pointer}></div>
                            {!!(add[0]?.reviews_count) && <RatingStars editable={false} value={add[0]?.reviews_average} />}
                            {!(add[0]?.reviews_count === 0) ? <p>{reviews} - {add[0]?.reviews_count}</p> : <p style={{ margin: "auto" }}>{addreview}</p>}
                        </div>
                    </div>
                </div>
                ) : <></>}
                {loadedWebsites ? websites.map((el, i) => {
                    return <div key={i} className={clsx(styles.website, "flex-between")}>
                        {/* <div className={styles.rate}> */}
                        <span className={clsx(styles.rate, el?.position_diff > 0 ? styles.green : styles.red)} data-rate={el?.position_diff !== 0 ? (el?.position_diff) : ""}>{i + 1}</span>
                        {/* </div> */}
                        <div className={clsx(styles.info, "flex-between")}>
                            <div className={styles.main}>
                                <div className={clsx(styles.name, "flex-between")}>
                                    {!!(el?.favicon) && <img src={el?.favicon} />}
                                    <a href={"https://" + el?.host} target='_blank'>{el?.title}</a>
                                </div>
                                <p>{el?.description}</p>
                                <div className={clsx("flex-between")}>
                                    <div className={clsx(styles.local__link, "flex-between")}>

                                        {el?.is_local && <div className={styles.local}>Local</div>}
                                        {el?.host && <a className={styles.link} href={"https://" + el?.host} target='_blank'>
                                            {el?.host}
                                        </a>}
                                    </div>
                                    <div className={styles.traffic}><span>{traffic} {inCountry}:</span> {el?.traffic !== null && el?.traffic.toLocaleString()}</div>
                                </div>
                            </div>
                            <div className={styles.views} data-toolbar={view} onClick={() => dispatch(setPopupInfo({ type: "site", id: el?.id, item: el, position: i+1 }))}>
                                <div className={styles.pointer}></div>
                                {!!(el?.reviews_count) && <RatingStars editable={false} value={el?.reviews_average} />}
                                {!(el?.reviews_count === 0) ? <p>{reviews} - {el?.reviews_count}</p> : <p style={{ margin: "auto" }}>{addreview}</p>}
                            </div>
                        </div>
                    </div>
                }) : <Loading />}
            </div>
            {!(page >= countPage) && <button className={styles.show__more_btn} onClick={showMore}>{mores}</button>}
        </div>
        {showFormMobile && <AddAgency countriesInfo={countriesInfo} addAgencyRef={addAgencyRef} />}
        {showFormMobile &&
            (country === "europe"
                ? <>
                    <ProstitutionInfoTop loaded={loaded} prostitutionInfoEu={prostitutionInfoEu.top} />
                    <Map loaded={loaded} countriesInfo={countriesInfo} />
                    <ProstitutionInfoBottom loaded={loaded} prostitutionInfoEu={prostitutionInfoEu.bottom} />
                </>
                : (!!prostitutionInfo === true && <ProstitutionInfo loaded={loaded} prostitutionInfo={prostitutionInfo} />))
        }
    </div>
}

export default TopWebsites