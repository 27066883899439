import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import clsx from 'clsx'

import LanguageDropdown from '../language-dropdown/LanguageDropdown'
import PopupToggleTheme from '../popup-toggle-theme/PopupToggleTheme'

import styles from './adult-popup.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { toggleTheme } from '../../store/userSlice/userSlice'

const AdultPopup = ({ setAdultPopup, cookiesToggle, setCookiesToggle }) => {

    const { lang, country } = useParams()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { theme } = useSelector(state => state.user)
    const { mainWords } = useSelector(state => state.language)
    const { text, cookies, selectLang, age, yes, no } = mainWords

    const close = () => {
        if (!cookiesToggle) {
            const cookies = Cookies.get();
            for (const cookieName in cookies) {
                console.log(cookieName)
                Cookies.remove(cookieName);
            }
        } else {
            Cookies.set("theme", theme)
            dispatch(toggleTheme({ theme: theme, cookiesToggle: cookiesToggle }))
        }
        setAdultPopup(prev => !prev)
        document.body.style.overflow = "visible"
    }

    return <div className={styles.wrapper}>
        <div className={clsx(styles.container, "flex-column")}>
            <div className={clsx(styles.rect, "flex-center")}>18+</div>
            <p className={styles.p}>{text}</p>
            <p className={styles.p}>{cookies}</p>
            <div className={clsx(styles.cookies, "flex-center")}>
                <div>Cookies</div>
                <label>
                    <input className={clsx(cookiesToggle && styles.active)} value={cookiesToggle} type="checkbox" onChange={() => setCookiesToggle(prev => !prev)} />
                    <span className={styles.on}>On</span>
                    <span className={styles.off}>Off</span>
                </label>
            </div>
            <span className={styles.h2}>{selectLang}</span>
            <div className={clsx(styles.toggle__wrapper, "flex-between")}>
                <LanguageDropdown />
                <PopupToggleTheme />
            </div>
            <span className={styles.h2}>{age}</span>
            <div className={clsx(styles.accept, "flex-center")}>
                <button onClick={close}>{yes}</button>
                {/* <Link to={pathname.includes("/meth") ? `/${lang}/meth` : (pathname.includes("/edit") ? `/${lang}/edit` : (pathname.includes(country) ? `/${lang}/${country}` : ""))} onClick={close}>Да – вход на сайт</Link> */}
                <a href="https://www.google.com">{no}</a>
            </div>
        </div>
    </div>
}

export default AdultPopup