import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import clsx from 'clsx';

import { toggleTheme } from '../../store/userSlice/userSlice';

import styles from './popup-toggle-theme.module.scss'

const PopupToggleTheme = () => {
    const dispatch = useDispatch()

    const { mainWords } = useSelector(state => state.language)

    const { light, dark } = mainWords

    return <div className={clsx(styles.toggle__theme, "flex-between")}>
        <div onClick={() => dispatch(toggleTheme({ theme: "light", cookiesToggle: !!Cookies.get('theme') }))}>
            <svg className={clsx(styles.sun, styles.theme__icon)} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" title={light}>
                <path d="M14.9998 10.5001C14.9998 13.2615 12.7613 15.5001 9.99984 15.5001C7.23841 15.5001 4.99984 13.2615 4.99984 10.5001C4.99984 7.73866 7.23841 5.50008 9.99984 5.50008C12.7613 5.50008 14.9998 7.73866 14.9998 10.5001Z" fill="#F3C947" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.99984 1.54175C10.345 1.54175 10.6248 1.82157 10.6248 2.16675V3.00008C10.6248 3.34526 10.345 3.62508 9.99984 3.62508C9.65466 3.62508 9.37484 3.34526 9.37484 3.00008V2.16675C9.37484 1.82157 9.65466 1.54175 9.99984 1.54175ZM3.66535 4.16559C3.90942 3.92151 4.30515 3.92151 4.54923 4.16559L4.87659 4.49295C5.12067 4.73703 5.12067 5.13276 4.87659 5.37684C4.63252 5.62091 4.23679 5.62091 3.99271 5.37684L3.66535 5.04947C3.42127 4.8054 3.42127 4.40967 3.66535 4.16559ZM16.3341 4.16581C16.5782 4.40988 16.5782 4.80561 16.3341 5.04969L16.0067 5.37705C15.7627 5.62113 15.3669 5.62113 15.1229 5.37705C14.8788 5.13298 14.8788 4.73725 15.1229 4.49317L15.4502 4.16581C15.6943 3.92173 16.09 3.92173 16.3341 4.16581ZM1.0415 10.5001C1.0415 10.1549 1.32133 9.87508 1.6665 9.87508H2.49984C2.84502 9.87508 3.12484 10.1549 3.12484 10.5001C3.12484 10.8453 2.84502 11.1251 2.49984 11.1251H1.6665C1.32133 11.1251 1.0415 10.8453 1.0415 10.5001ZM16.8748 10.5001C16.8748 10.1549 17.1547 9.87508 17.4998 9.87508H18.3332C18.6783 9.87508 18.9582 10.1549 18.9582 10.5001C18.9582 10.8453 18.6783 11.1251 18.3332 11.1251H17.4998C17.1547 11.1251 16.8748 10.8453 16.8748 10.5001ZM15.1229 15.6231C15.3669 15.379 15.7627 15.379 16.0067 15.6231L16.3341 15.9505C16.5782 16.1946 16.5782 16.5903 16.3341 16.8344C16.09 17.0784 15.6943 17.0784 15.4502 16.8344L15.1229 16.507C14.8788 16.2629 14.8788 15.8672 15.1229 15.6231ZM4.87659 15.6233C5.12067 15.8674 5.12067 16.2631 4.87659 16.5072L4.54923 16.8346C4.30515 17.0787 3.90942 17.0787 3.66535 16.8346C3.42127 16.5905 3.42127 16.1948 3.66535 15.9507L3.99271 15.6233C4.23679 15.3792 4.63252 15.3792 4.87659 15.6233ZM9.99984 17.3751C10.345 17.3751 10.6248 17.6549 10.6248 18.0001V18.8334C10.6248 19.1786 10.345 19.4584 9.99984 19.4584C9.65466 19.4584 9.37484 19.1786 9.37484 18.8334V18.0001C9.37484 17.6549 9.65466 17.3751 9.99984 17.3751Z" fill="#F3C947" />
            </svg>
            <p>{light}</p>
        </div>
        <div onClick={() => dispatch(toggleTheme({ theme: "dark", cookiesToggle: !!Cookies.get('theme') }))}>
            <svg className={clsx(styles.moon, styles.theme__icon)} width="20" height="21" viewBox="0 0 20 21" fill="F3C947" xmlns="http://www.w3.org/2000/svg" title={dark}>
                <path d="M9.99984 18.8334C14.6022 18.8334 18.3332 15.1025 18.3332 10.5001C18.3332 10.1145 17.7553 10.0508 17.5559 10.3808C16.6073 11.9506 14.8845 13.0001 12.9165 13.0001C9.92496 13.0001 7.49984 10.575 7.49984 7.58341C7.49984 5.61546 8.54932 3.89263 10.1191 2.94405C10.4491 2.74464 10.3854 2.16675 9.99984 2.16675C5.39746 2.16675 1.6665 5.89771 1.6665 10.5001C1.6665 15.1025 5.39746 18.8334 9.99984 18.8334Z" fill="#0B1421" fillOpacity="1" />
            </svg>
            <p>{dark}</p>
        </div>
    </div>
}

export default PopupToggleTheme