import React, { useEffect, useState } from 'react'
import "./NotPage.scss"


export const NotPage = () => {
  const lang = window.navigator.language.slice(0, 2)
  const [laung, setLaung] = useState("en")
  const [laungText, setLaungText] = useState("en")

  useEffect(() => {
    switch (lang) {
      case "ru":
        setLaung("ru")
        setLaungText("На главную")
        break
      case "es":
        setLaung("es")
        setLaungText("Principal")
        break
      case "de":
        setLaung("de")
        setLaungText("Zur Hauptsache")
        break
      case "cz":
        setLaung("cz")
        setLaungText("Na hlavní")
        break
      default:
        setLaung("en")
        setLaungText("Home")
    }
  }, [])
  return (
    <div className='not-found'>
      <div className="container">
        <div className="not-found__title">404 not found</div>
        <a className='not-found__link' href={`/${laung}/europe`}>{laungText}</a>
      </div>
    </div>
  )
}
