import parse from 'html-react-parser';

import Loading from '../loading/Loading';

import './prostitution-info-top.scss'

const ProstitutionInfoTop = ({ prostitutionInfoEu, loaded }) => {
    return <div className="wrapper">
        {(loaded && !!prostitutionInfoEu ? parse(prostitutionInfoEu) : <Loading />)}
    </div>
}

export default ProstitutionInfoTop