import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    theme: !!Cookies.get('theme') ? Cookies.get('theme') : "dark",
    authPopupIsOpen: false,
    signedIn: false
  },
  reducers: {
    toggleTheme: (state, action) => {
      if (state.theme === action.payload.theme) {
        return
      } else {
        if (action.payload.cookiesToggle) {
          state.theme = action.payload.theme
          Cookies.set('theme', action.payload.theme);
        } else {
          state.theme = action.payload.theme
        }
      }
    },
    setAuthPopupIsOpen: (state, action) => {
      state.authPopupIsOpen = !state.authPopupIsOpen;
    },
    setSignedIn: (state, action) => {
      state.signedIn = action.payload
    }
  },
});

export const { toggleTheme, setAuthPopupIsOpen, setSignedIn } = userSlice.actions;
export default userSlice.reducer;