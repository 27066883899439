import { useSelector } from "react-redux";
import clsx from "clsx";

import Menu from "../menu/Menu";

import countries from "../../main-translations/countries.json"

import styles from "./header.module.scss";
import { useParams } from "react-router-dom";

const Header = ({ countriesInfo, signedIn, addAgencyRef }) => {
  const { mainWords, selectedLanguage, selectedCountry } = useSelector((state) => state.language);

  return (
    <header>
      <Menu mainWords={mainWords} countriesInfo={countriesInfo} signedIn={signedIn} addAgencyRef={addAgencyRef} />
    </header>
  );
};

export default Header;
