import { configureStore } from '@reduxjs/toolkit';

import userSlice from './userSlice/userSlice'
import languageSlice from './languageSlice/languageSlice';
import reviewSlice from './reviewSlice/reviewSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    language: languageSlice,
    review: reviewSlice,
  },
});

export default store;