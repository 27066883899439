import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";

import { setCountry } from "../../store/languageSlice/languageSlice";
import { setAuthPopupIsOpen } from "../../store/userSlice/userSlice";

import LanguageDropdown from "../language-dropdown/LanguageDropdown";
import ToggleTheme from "../toggle-theme/ToggleTheme";

import logoDark from "../../images/logo-dark.svg";
import logoLight from "../../images/logo-light.svg";

import styles from "./menu.module.scss";
import { useEffect, useState } from "react";
import { checkAuth } from "../../api/requests";

const Menu = ({ signedIn, countriesInfo, addAgencyRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { lang, country } = useParams()

  const { theme, authPopupIsOpen } = useSelector((state) => state.user);
  const { mainWords, selectedLanguage, selectedCountry } = useSelector((state) => state.language);

  const { meth, add, login, mainLogoTitle, mainLogoAlt } = mainWords

  const toMain = (e) => {
    e.preventDefault();

    navigate(`/${selectedLanguage}/europe`)
    dispatch(setCountry("europe"))
  }

  const inCountry = countriesInfo.filter((el) => {
    let key = Object.keys(el)[0];
    if (!!country) {
      if (key === country) {
        return el;
      }
    } else {
      if (key === "europe") {
        return el;
      }
    }
  })[0][!!country ? country : "europe"];

  const toAddAgency = (e) => {
    e.preventDefault();
    
    addAgencyRef.current?.scrollIntoView({ behavior: 'smooth' });
    navigate(`/${selectedLanguage}/${selectedCountry}/#addAgency`)
  }

  return (
    <div className={clsx(styles.menu, "flex-between")}>
      <Link to={`/`} title={`${mainLogoTitle} ${inCountry[1]} - EroTop`} onClick={(e) => toMain(e)} >
        <img src={theme === "light" ? logoLight : logoDark} alt={`${mainLogoAlt} ${inCountry[1]}`} />
      </Link>
      <nav className={clsx(styles.header__nav, "flex-between")}>
        <Link to={`/${selectedLanguage}/meth`} className={styles.nav__rating_link}>
          {meth}
        </Link>
        <Link to={`/${selectedLanguage}/#addAgency`} className={styles.nav__add_site_link} onClick={toAddAgency}>
          {add}
        </Link>
        {signedIn ? <Link to={`${lang}/edit`} className={styles.nav__login_link}>{login}</Link> : <Link to={`${lang}/${country}/login`} className={styles.nav__login_link}>
          {login}
        </Link>}
        {signedIn ? <Link to={`${lang}/edit`} className={clsx(styles.nav__login_link, styles.mobile__login_link)}></Link> : <a href="" className={clsx(styles.nav__login_link, styles.mobile__login_link)} onClick={(e) => {
          e.preventDefault()
          dispatch(setAuthPopupIsOpen())
        }}>
        </a>}
        {signedIn && <Link to="/logout" className={styles.nav__logout_link} onClick={() => navigator.location.reload()}></Link>}
        <LanguageDropdown />
        <ToggleTheme mainWords={mainWords} />
      </nav>
    </div>
  );
};

export default Menu;
