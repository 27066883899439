import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { hydrate, render } from "react-dom";

import App from './components/app/App';

import store from './store/store';

import './index.scss';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
// <Provider store={store}>
//   <Router>
//     <App />
//   </Router>
// </Provider>
// );
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>, rootElement);
}