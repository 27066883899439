import { useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import clsx from "clsx";
import Cookies from "js-cookie";
import { setCountries, setCountryInfo } from '../../store/languageSlice/languageSlice';

import { setSignedIn, toggleTheme } from '../../store/userSlice/userSlice';

import { setCountry, setLanguage, setMainWords } from '../../store/languageSlice/languageSlice';
import { checkAuth, checkRegion, getCookies, getRegions } from '../../api/requests'

import Page from '../page/Page'
import Main from "../main/Main";
import EditInfo from '../edit-info/EditInfo';
import Methodology from '../methodology/Methodology';
import Loading from "../loading/Loading";

import mainWords from '../../main-translations/main-words.json'
import './app.scss'
import { NotPage } from "../not-page/NotPage";
import axios from "axios";


const App = () => {
  const addAgencyRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { theme, signedIn } = useSelector(state => state.user)
  const { selectedLanguage, selectedCountry } = useSelector(state => state.language)
  const [loaded, setLoaded] = useState(false)

  const [countriesInfo, setCountriesInfo] = useState([])
  // const [signedIn, setSignedIn] = useState(null)
  const [adultPopup, setAdultPopup] = useState(!!!Cookies.get("theme"))
  const [cookiesToggle, setCookiesToggle] = useState(true)

  var lang = (pathname.split("/")[1] === "ru" || pathname.split("/")[1] === "cz" || pathname.split("/")[1] === "de" || pathname.split("/")[1] === "en" || pathname.split("/")[1] === "es") ? pathname.split("/")[1] : ""
  
  var country
  if (!pathname.includes("meth") || !pathname.includes("edit")) {
    country = pathname.split("/")[2]
  }

  const [countries, setCountries] = useState([]);

  const getRegionsInfo = () => {
    setLoaded(false)
    if (!!(pathname.split("/")[1])) {
      getRegions(pathname.split("/")[1]).then(res => {
        if (res.status === 200) {
          const keys = res.data.data.map(obj => Object.keys(obj)[0]);
          setCountries(keys);
          setCountriesInfo(res.data.data);
          // setCountryInfo(res.data.data)
          setLoaded(true)
        }
      })
    }
  }


  useEffect(() => {
    if (lang !== "ru" && lang !== "cz" && lang !== "de" && lang !== "es" && lang !== "en") {
      if (pathname === "/") {
        return
      }
      setLoaded(true)
      navigate("/404")
    }
  }, [])
  useEffect(() => {
    const location = pathname.split("/")[2]
    if(lang && location && !!countries.length) {
      if(location === "meth" || location === "login"|| location === "edit") {
        return
      }
      if(!countries.includes(location)) {
        setLoaded(true)
        // navigate('/404')
      }
    }
  }, [pathname, countries])

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      if (!theme) dispatch(toggleTheme({ theme: "light", cookiesToggle: cookiesToggle }));
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (!theme) dispatch(toggleTheme({ theme: "dark", cookiesToggle: cookiesToggle }));
    }

    if (adultPopup) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    dispatch(setLanguage(lang))
    dispatch(setMainWords(mainWords[lang]))
    let currntLocal = window.location.hash
    if (!!!country) {
      if (!lang && pathname !== "/") {
        return
      }
      dispatch(setCountry("europe"))
      navigate(`/${lang}/europe${currntLocal ? currntLocal : ""}`)
    } else if (selectedCountry === null && !!country) {
      sessionStorage.setItem("country", country)
      dispatch(setCountry(country))
      navigate(`/${lang}/${sessionStorage.getItem("country")}${currntLocal ? currntLocal : ""}`)
    }

    getRegionsInfo()
    checkAuth().then(res => {
      if (res.status === 200) {
        dispatch(setSignedIn(true))
      } else {
        dispatch(setSignedIn(false))
      }
    }).catch(e => console.error(e))

  }, [])

  useEffect(() => {
    if (!lang && pathname !== "/") {
      setLoaded(true)
      return
    }
    dispatch(setMainWords(mainWords[lang]))

    getRegionsInfo()
  }, [lang])

 

  return loaded ? <div className={clsx("page", theme)}>
    <Routes>
      <Route exact path="/" element={<Page countriesInfo={countriesInfo} signedIn={signedIn} addAgencyRef={addAgencyRef} adultPopup={adultPopup} setAdultPopup={setAdultPopup} cookiesToggle={cookiesToggle} setCookiesToggle={setCookiesToggle} />}>
        <Route path=":lang/:country/login" element={<Main countriesInfo={countriesInfo} addAgencyRef={addAgencyRef} />} />
        <Route path=":lang/login" element={<Main countriesInfo={countriesInfo} addAgencyRef={addAgencyRef} />} />
        <Route path=":lang/:country" element={<Main countriesInfo={countriesInfo} addAgencyRef={addAgencyRef} />} />
        <Route path=":lang/edit" element={<EditInfo countriesInfo={countriesInfo} />} />
        <Route path=":lang/:country/meth" element={<Methodology />} />
        <Route path=":lang/meth" element={<Methodology />} />
      </Route>
      <Route path="*" element={<NotPage/>}/>
    </Routes>
  </div> : <div className={clsx("loading__wrapper", theme)}><Loading /></div>
}

export default App;
