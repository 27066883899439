import { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import clsx from "clsx";

import {
  setLanguage,
  setMainWords,
} from "../../store/languageSlice/languageSlice";

import { setSentPopupIsOpen } from "../../store/reviewSlice/reviewSlice";

import RatingStars from "../rating-stars/RatingStars";

import widgetS1Light from "../../images/size-s-l-1.webp";
import widgetS1Dark from "../../images/size-s-d-1.webp";
import widgetS10Light from "../../images/size-s-l-10.webp";
import widgetS10Dark from "../../images/size-s-d-10.webp";
import widgetM1Light from "../../images/size-m-l-1.webp";
import widgetM1Dark from "../../images/size-m-d-1.webp";
import widgetM10Light from "../../images/size-m-l-10.webp";
import widgetM10Dark from "../../images/size-m-d-10.webp";
import widgetL1Light from "../../images/size-l-l-1.webp";
import widgetL1Dark from "../../images/size-l-d-1.webp";
import widgetL10Dark from "../../images/size-l-d-10.webp";
import widgetL10Light from "../../images/size-l-l-10.webp";

import styles from "./edit-info.module.scss";
import {
  getAccInfo,
  getReviews,
  saveAccInfo,
  getRegions,
} from "../../api/requests";

const EditInfo = ({ countriesInfo }) => {
  const { theme } = useSelector((state) => state.user);
  const { mainWords, selectedLanguage, selectedCountry } = useSelector(
    (state) => state.language
  );
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { lang } = useParams();

  const {
    name,
    description,
    phone,
    save,
    reviews,
    copy,
    size,
    color,
    generate,
    widget,
    badge,
    widget__text1,
    widget__text10,
  } = mainWords;

  const [widgetSize, setWidgetSize] = useState("l");
  const [widgetTheme, setWidgetTheme] = useState("light");
  const [widgetImage, setWidgetImage] = useState(widgetS1Light);
  const [widgetText, setWidgetText] = useState("");

  const [type, setType] = useState("agency");
  const [topPlace, setTopPlace] = useState("top1");
  const [region, setRegion] = useState(null);
  const [image, setImage] = useState(null);
  const [reviewsArray, setReviewsArray] = useState([]);
  const [inputValues, setInputValues] = useState({
    name: "",
    description: "",
    phone: "",
    url: "",
  });
  const widgetHtmls = {
    light: {
      s: {
        top1: '<a href="https://erotop10.com"><img src="https://erotop10.com/widgets/widget-small-light1.webp" width="88px"></a>',
        top10:
          '<a href="https://erotop10.com"><img src="https://erotop10.com/widgets/widget-small-light10.webp" width="88px"></a>',
      },
      m: {
        top1: `<a href="https://erotop10.com" style="box-sizing:border-box;width:185px;height:75px;display:block;background: url(https://erotop10.com/widgets/widget-medium-light1.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:9px;padding:26px 5px 0px 75px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
        top10: `<a href="https://erotop10.com" style="box-sizing:border-box;width:185px;height:75px;display:block;background: url(https://erotop10.com/widgets/widget-medium-light10.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:9px;padding:26px 5px 0px 75px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
      },
      l: {
        top1: `<a href="https://erotop10.com" style="box-sizing:border-box;display:block;width:350px;height:144px;background:url(https://erotop10.com/widgets/widget-large-light1.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:15px;padding:54px 23px 0 160px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
        top10: `<a href="https://erotop10.com" style="box-sizing:border-box;display:block;width:350px;height:144px;background:url(https://erotop10.com/widgets/widget-large-light10.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:15px;padding:54px 23px 0 160px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
      },
    },
    dark: {
      s: {
        top1: '<a href="https://erotop10.com"><img src="https://erotop10.com/widgets/widget-small-dark1.webp" width="88px"></a>',
        top10:
          '<a href="https://erotop10.com"><img src="https://erotop10.com/widgets/widget-small-dark10.webp" width="88px"></a>',
      },
      m: {
        top1: `<a href="https://erotop10.com" style="box-sizing:border-box;width:185px;height:75px;display:block;background: url(https://erotop10.com/widgets/widget-medium-dark1.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:9px;padding:26px 5px 0px 75px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
        top10: `<a href="https://erotop10.com" style="box-sizing:border-box;width:185px;height:75px;display:block;background: url(https://erotop10.com/widgets/widget-medium-dark10.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:9px;padding:26px 5px 0px 75px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
      },
      l: {
        top1: `<a href="https://erotop10.com" style="box-sizing:border-box;display:block;width:350px;height:144px;background:url(https://erotop10.com/widgets/widget-large-dark1.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:15px;padding:54px 23px 0 160px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
        top10: `<a href="https://erotop10.com" style="box-sizing:border-box;display:block;width:350px;height:144px;background:url(https://erotop10.com/widgets/widget-large-dark10.webp)top left/cover;font-weight:700;text-align:center;color:#f1e482;font-size:15px;padding:54px 23px 0 160px;text-decoration:none;font-family:Inter;">${widgetText} <br/>${region}</a>`,
      },
    },
  };
  const [showData, setShowData] = useState({
    name: "",
    description: "",
    phone: "",
    url: "",
    position: null,
    reviewsCount: null,
    reviewsAverage: "",
    region: "",
  });
  const inCountry = countriesInfo.filter((el) => {
    let key = Object.keys(el)[0];
    if (!!showData.region) {
      if (key === showData.region) {
        return el;
      }
    } else {
      if (key === "europe") {
        return el;
      }
    }
  })[0][!!showData.region ? showData.region : "europe"];
  const widgetSizes = {
    light: {
      s: {
        top1: widgetS1Light,
        top10: widgetS10Light,
      },
      m: {
        top1: widgetM1Light,
        top10: widgetM10Light,
      },
      l: {
        top1: widgetL1Light,
        top10: widgetL10Light,
      },
    },
    dark: {
      s: {
        top1: widgetS1Dark,
        top10: widgetS10Dark,
      },
      m: {
        top1: widgetM1Dark,
        top10: widgetM10Dark,
      },
      l: {
        top1: widgetL1Dark,
        top10: widgetL10Dark,
      },
    },
  };

  const sizes = [
    {
      size: "L (350x144)",
      name: "l",
    },
    {
      size: "M (185x75)",
      name: "m",
    },
    {
      size: "S (88x31)",
      name: "s",
    },
  ];

  const themes = ["Light", "Dark"];

  const onInputChange = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setWidgetImage(widgetSizes[widgetTheme][widgetSize][topPlace]);
  }, [widgetSize, widgetTheme, topPlace]);

  useEffect(() => {
    if (!selectedLanguage) {
      dispatch(setLanguage("en"));
      dispatch(setMainWords(mainWords["en"]));
    }
  }, [pathname]);

  useEffect(() => {
    getAccInfo(lang).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.position == 1) {
          setTopPlace("top1");
        } else {
          setTopPlace("top10");
        }
        setType(res?.data?.type);

        setImage(res?.data?.image);
        setInputValues({
          name: res?.data?.name,
          description: res?.data?.description,
          phone: res?.data?.phone,
          url: res?.data?.website,
        });
        setShowData({
          name: res?.data?.name,
          description: res?.data?.description,
          phone: res?.data?.phone,
          url: res?.data?.website,
          position: res?.data?.position,
          reviewsCount: res?.data?.reviews_count,
          reviewsAverage: res?.data?.reviews_average,
          region: res?.data.region,
        });
        getReviews(lang, res?.data?.type, res?.data?.id).then((resReviews) => {
          if (resReviews.status === 200) {
            setReviewsArray(resReviews?.data?.data);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    getRegions("en").then((res) => {
      let currentReg = res.data.data.filter((i) => {
        let isRegion = Object.keys(i)[0];
        if (isRegion === showData.region) return true;
      })[0][showData.region][1];

      setRegion(currentReg)
      if (topPlace === "top1" && type === "agency") {
        setWidgetText("The Best Escort Agency");
      } else if (topPlace === "top1" && type === "site") {
        setWidgetText("The Best Escort Site");
      } else if (topPlace === "top10" && type === "agency") {
        setWidgetText("Top-10 Escort Agency");
      } else if (topPlace === "top10" && type === "site") {
        setWidgetText("Top-10 Escort Site");
      }
    });
  }, [showData]);

  const copyCode = () => {
    navigator.clipboard.writeText(
      widgetHtmls[widgetTheme][widgetSize][topPlace]
    );
    dispatch(setSentPopupIsOpen("Code has been copied!"));
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      dispatch(setSentPopupIsOpen(""));
      document.body.style.overflow = "visible";
    }, 1000);
  };

  const setAccInfoFunc = () => {
    saveAccInfo(
      lang,
      inputValues.name,
      inputValues.description,
      inputValues.phone,
      inputValues.url
    )
      .then((res) => {
        if (res.status) {
          dispatch(setSentPopupIsOpen("Saved Successfully!"));
          document.body.style.overflow = "hidden";
          setTimeout(() => {
            dispatch(setSentPopupIsOpen(""));
            document.body.style.overflow = "visible";
          }, 2000);
          // window.location.reload();
        }
      })
      .catch((e) => console.log(`error send info ${e}`));
  };

  return (
    <div className={clsx("edit", theme, "flex-center")}>
      <div className={styles.container}>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setAccInfoFunc();
            }}
          >
            {type === "site" ? (
              <>
                <label>
                  <p>{name}</p>
                  <input
                    type="text"
                    name="name"
                    value={inputValues.name}
                    placeholder="Name website"
                    onChange={onInputChange}
                    required
                  />
                </label>
                <label>
                  <p>{description}</p>
                  <textarea
                    type="text"
                    name="description"
                    value={inputValues.description}
                    onChange={onInputChange}
                    placeholder="The best escort service in Czech Republic for European escort girls. Independent outcall European escort girls in and around Czech Republic."
                    required
                  />
                </label>
              </>
            ) : (
              <>
                <label>
                  <p>{name}</p>
                  <input
                    type="text"
                    name="name"
                    value={inputValues.name}
                    placeholder="Ace Escorts Agency"
                    onChange={onInputChange}
                    required
                  />
                </label>
                <label>
                  <p>{description}</p>
                  <textarea
                    type="text"
                    name="description"
                    value={inputValues.description}
                    onChange={onInputChange}
                    placeholder="The best escort service in Czech Republic for European escort girls. Independent outcall European escort girls in and around Czech Republic."
                    required
                  />
                </label>
                <label>
                  <p>{phone}</p>
                  <input
                    type="number"
                    name="phone"
                    value={inputValues.phone}
                    placeholder="Phone number"
                    onChange={onInputChange}
                    required
                  />
                </label>
                <label>
                  <p>Web-site</p>
                  <input
                    type="text"
                    name="url"
                    value={inputValues.url}
                    placeholder="URL"
                    onChange={onInputChange}
                    required
                  />
                </label>
              </>
            )}
            <button type="submit">{save}</button>
          </form>
          {type === "agency" ? (
            <div className={clsx(styles.agency, "flex-start")}>
              {image && <img src={image} />}
              <div className={styles.right}>
                <a
                  className={styles.title}
                  href={`https://${showData.url}`}
                  target="_blank"
                >
                  {showData.name}
                </a>
                <p className={styles.text}>{showData.description}</p>
                <div className={styles.contacts}>
                  {/* <p>Koněvova 188/32, Praha 3</p> */}
                  <a className={styles.phone} href={`tel:${showData.phone}`}>
                    +{showData.phone}
                  </a>
                  <a
                    className={styles.link_site}
                    href={`https://${showData.url}`}
                    target="_blank"
                  >
                    {showData.url}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.27647 2.33332L9.27647 2.33332C9.64466 2.33332 9.94314 2.6318 9.94313 2.99999L9.94314 8.99999C9.94314 9.36818 9.64466 9.66666 9.27647 9.66666C8.90828 9.66666 8.6098 9.36818 8.6098 8.99999L8.6098 4.60946L3.08121 10.1381C2.82086 10.3984 2.39875 10.3984 2.1384 10.1381C1.87805 9.87771 1.87805 9.4556 2.1384 9.19525L7.66699 3.66666L3.27647 3.66666C2.90828 3.66666 2.6098 3.36818 2.6098 2.99999C2.6098 2.6318 2.90828 2.33332 3.27647 2.33332Z"
                        fill="#4A8FF3"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.website}>
              <div className={clsx(styles.website__header, "flex-between")}>
                {showData.position && (
                  <div className={styles.rate}>
                    {/* <span></span> */}
                    {showData.position}
                  </div>
                )}
                <div className={styles.header__right}>
                  <div className={clsx(styles.right__name)}>
                    {image && <img src={image} />}
                    {showData.name && (
                      <a
                        href={`https://${showData.url}`}
                        target="_blank"
                        className={styles.title}
                      >
                        {showData.name}
                      </a>
                    )}
                  </div>
                  {showData.description && (
                    <p className={styles.text}>{showData.description}</p>
                  )}
                  {showData.url && (
                    <a
                      className={styles.link_site}
                      href={`https://${showData.url}`}
                      target="_blank"
                    >
                      {showData.url}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.27647 2.33332L9.27647 2.33332C9.64466 2.33332 9.94314 2.6318 9.94313 2.99999L9.94314 8.99999C9.94314 9.36818 9.64466 9.66666 9.27647 9.66666C8.90828 9.66666 8.6098 9.36818 8.6098 8.99999L8.6098 4.60946L3.08121 10.1381C2.82086 10.3984 2.39875 10.3984 2.1384 10.1381C1.87805 9.87771 1.87805 9.4556 2.1384 9.19525L7.66699 3.66666L3.27647 3.66666C2.90828 3.66666 2.6098 3.36818 2.6098 2.99999C2.6098 2.6318 2.90828 2.33332 3.27647 2.33332Z"
                          fill="#4A8FF3"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          {!!showData.reviewsCount && (
            <div className={clsx(styles.count, "flex-between")}>
              <RatingStars
                editable={false}
                value={showData.reviewsAverage.toFixed(1)}
              />
              <p>
                {showData.reviewsCount} <span>{reviews}</span>
              </p>
            </div>
          )}
          {reviewsArray.length !== 0 && (
            <div className={styles.reviews}>
              {reviewsArray.map((el, i) => {
                return (
                  <div className={styles.item}>
                    <div className={clsx(styles.header, "flex-between")}>
                      <div className={clsx(styles.person, "flex-between")}>
                        <div className={styles.info}>
                          <p className={styles.date}>{el?.created_at}</p>
                          <p className={styles.name}>{el?.name}</p>
                        </div>
                      </div>
                      <RatingStars
                        editable={false}
                        value={el?.score}
                        size={15}
                      />
                    </div>
                    <p className={styles.text}>{el?.body}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.widget}>
            <div className={styles.title}>
              <h1>{widget}</h1>
              <p>{badge}</p>
            </div>
            <div className={styles.size}>
              <p>{size}</p>
              <div className={clsx(styles.size__options, "flex-between")}>
                {sizes.map((el, i) => (
                  <button
                    key={i}
                    className={clsx(
                      styles.size__option,
                      widgetSize === el.name && styles.size__selected
                    )}
                    onClick={() => setWidgetSize(el.name)}
                  >
                    {el.size}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.theme}>
              <p>{color}</p>
              <div className={clsx(styles.theme__options, "flex-between")}>
                {themes.map((el, i) => (
                  <button
                    key={i}
                    className={clsx(styles.theme__option, "flex-center")}
                    onClick={() => setWidgetTheme(el.toLowerCase())}
                  >
                    <div
                      className={clsx(
                        widgetTheme === el.toLowerCase() &&
                          styles.theme__selected
                      )}
                    ></div>
                    {el}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.widget__img}>
              <div
                // data-country={widgetText}
                // data-website="erotop10.com"
                className={clsx(
                  styles.img__wrapper,
                  widgetSize === "s" && styles.wrapper__s,
                  widgetSize === "m" && styles.wrapper__m,
                  widgetSize === "l" && styles.wrapper__l,
                  widgetTheme === "light" ? styles.light : styles.dark
                )}
              >
                <p className={styles.widgetText1}>{widgetText} <br/>{region}</p>
                {/* <p className={styles.widgetText2}>erotop10.com</p> */}
                <img
                  src={widgetImage}
                  className={clsx(
                    widgetSize === "s" && styles.size__s,
                    widgetSize === "m" && styles.size__m,
                    widgetSize === "l" && styles.size__l
                  )}
                />
              </div>
              <div className={styles.code__wrapper}>
                <div className={styles.code}>
                  {widgetHtmls[widgetTheme][widgetSize][topPlace]}
                </div>
                <button onClick={copyCode}>{copy}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EditInfo);
