import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { setAuthPopupIsOpen } from "../../store/userSlice/userSlice";
import clsx from 'clsx'

import countries from '../../main-translations/countries.json'

import { getRegionInfo } from '../../api/requests'

import Countries from '../countries/Countries'
import TopAgencies from '../top-agencies/TopAgencies'
import TopWebsites from '../top-websites/TopWebsites'
import ToggleBlocks from '../toggle-blocks/ToggleBlocks'
import AddAgency from '../add-agency/AddAgency'
import ProstitutionInfoTop from '../prostitution-info-top/ProstitutionInfoTop'
import ProstitutionInfoBottom from '../prostitution-info-bottom/ProstitutionInfoBottom'
import Map from '../map/Map'

import styles from './main.module.scss'
import ProstitutionInfo from '../prostitution-info/ProstitutionInfo'

const Main = ({ countriesInfo, addAgencyRef }) => {

    const [toggleBlocks, setToggleBlocks] = useState(null)
    const [showFormMobile, setShowFormMobile] = useState(null)
    const { selectedLanguage, selectedCountry, mainWords } = useSelector(state => state.language)
    const { lang, country, edit } = useParams();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [prostitutionInfo, setProstitutionInfo] = useState("")
    const [prostitutionInfoEu, setProstitutionInfoEu] = useState({
        top: "",
        bottom: ""
    })
    const [loaded, setLoaded] = useState(null)


    const inCountry = countriesInfo.filter(el => {
        let key = Object.keys(el)[0]
        if (!!country) {
            if (key === country) {
                return el
            }
        } else {
            if (key === "europe") {
                return el
            }
        }
    })[0][!!country ? country : "europe"]

    const getRegionInfoFunc = () => {
        getRegionInfo(lang, country).then(res => {
            if (res.status === 200) {
                if (country === "europe") {
                    const prostitutionInfoTopBottom = res.data.data.body.split("%MAP%")
                    console.log(res)
                    setProstitutionInfoEu({
                        top: prostitutionInfoTopBottom[0],
                        bottom: prostitutionInfoTopBottom[1]
                    })
                    setTimeout(() => {
                        setLoaded(true)
                    }, 700)
                } else {
                    setProstitutionInfo(res.data.data.body)
                    setTimeout(() => {
                        setLoaded(true)
                    }, 700)
                }
            }
        })
    }

    useEffect(() => {
        setLoaded(false)
        if (window.innerWidth <= 768) {
            setToggleBlocks(true)
            setShowFormMobile(false)
        } else {
            setToggleBlocks(false)
            setShowFormMobile(true)
        }
        getRegionInfoFunc()
    }, [])

    useEffect(() => {
        setLoaded(false)
        getRegionInfoFunc()
    }, [selectedLanguage, country])

    return <main>
        <div className={styles.main__container}>
            <h1>{mainWords.h1} {inCountry[1]}</h1>
            <Countries countriesInfo={countriesInfo} />
            {!toggleBlocks ? <div className={clsx(styles.websites__agencies, "flex-between")}>
                <TopWebsites countriesInfo={countriesInfo} showFormMobile={showFormMobile} inCountry={inCountry[1]} prostitutionInfo={prostitutionInfo} prostitutionInfoEu={prostitutionInfoEu} loaded={loaded} addAgencyRef={addAgencyRef} />
                <TopAgencies countriesInfo={countriesInfo}/>
            </div>
                : <>
                    <ToggleBlocks countriesInfo={countriesInfo} />
                    {!showFormMobile && <AddAgency countriesInfo={countriesInfo} addAgencyRef={addAgencyRef} />}
                    {(country === "europe"
                        ? <>
                            <ProstitutionInfoTop prostitutionInfoEu={prostitutionInfoEu.top} loaded={loaded} />
                            <Map loaded={loaded} countriesInfo={countriesInfo} />
                            <ProstitutionInfoBottom prostitutionInfoEu={prostitutionInfoEu.bottom} loaded={loaded} />
                        </>
                        : (!!prostitutionInfo === true && <ProstitutionInfo prostitutionInfo={prostitutionInfo} loaded={loaded} />))}
                </>}
            {/* <Map countriesInfo={countriesInfo} /> */}
            {/* <ProstitutionInfoBottom /> */}
        </div>
    </main>
}

export default Main