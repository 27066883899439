import parse from 'html-react-parser';

import Loading from '../loading/Loading';

import './prostitution-info-bottom.scss'

const ProstitutionInfoBottom = ({ prostitutionInfoEu, loaded }) => {
    return <div className="wrapper">
        {(loaded && !!prostitutionInfoEu ? parse(prostitutionInfoEu) : <Loading />)}
    </div>
}

export default ProstitutionInfoBottom