import parse from 'html-react-parser';

import Loading from '../loading/Loading'

import './prostitution-info.scss'

const ProstitutionInfo = ({ prostitutionInfo, loaded }) => {
    return <div className="wrapper">
        {loaded && !!prostitutionInfo ? parse(prostitutionInfo) : <Loading />}
    </div>
}

export default ProstitutionInfo