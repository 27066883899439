import { createSlice } from '@reduxjs/toolkit';

const reviewSlice = createSlice({
  name: 'popup',
  initialState: {
    isOpen: null,
    type: null, // or agency
    id: null,
    item: null,
    sentPopupIsOpen: false,
    sentPopupText: "",
    position: 0
  },
  reducers: {
    setPopupInfo: (state, action) => {
      console.log(action.payload)
      const { type, id, item, position } = action.payload
      state.isOpen = !state.isOpen
      state.type = type
      state.id = id
      state.item = item
      state.position = position
    },
    setSentPopupIsOpen: (state, action) => {
      state.sentPopupIsOpen = !state.sentPopupIsOpen
      state.sentPopupText = action.payload
    }
  },
});

export const { setPopupInfo, setSentPopupIsOpen } = reviewSlice.actions;
export default reviewSlice.reducer;