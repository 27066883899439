import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import {
  setPopupInfo,
  setSentPopupIsOpen,
} from "../../store/reviewSlice/reviewSlice";
import { setAuthPopupIsOpen } from "../../store/userSlice/userSlice";
import { addReview, getReviews } from "../../api/requests";

import RatingStars from "../rating-stars/RatingStars";

import styles from "./agency-popup.module.scss";

const AgencyPopup = () => {
  const dispatch = useDispatch();

  const { mainWords, selectedLanguage } = useSelector(
    (state) => state.language
  );
  const { isOpen, type, id, item, position } = useSelector(
    (state) => state.review
  );
  const { lang, country } = useParams();

  const [agencyReviews, setAgencyReviews] = useState([]);

  const [reviewInputs, setReviewInputs] = useState({
    name: "",
    reviewText: "",
  });
  const [reviewScore, setReviewScore] = useState(5);

  const { reviews, addreview, name1, review, score, edit, reviewSent } =
    mainWords;

  const onChangeInput = (e) => {
    setReviewInputs({
      ...reviewInputs,
      [e.target.name]: e.target.value,
    });
  };

  const addReviewFunc = () => {
    if (
      !!lang &&
      !!type &&
      !!id &&
      !!reviewInputs.name &&
      !!reviewInputs.reviewText &&
      !!reviewScore
    ) {
      addReview(
        lang,
        type,
        id,
        reviewInputs.name,
        reviewInputs.reviewText,
        reviewScore
      ).then((res) => {
        if (res.status === 200) {
          dispatch(setSentPopupIsOpen(reviewSent));
          document.body.style.overflow = "hidden";
          setTimeout(() => {
            dispatch(setSentPopupIsOpen(""));
            document.body.style.overflow = "visible";
          }, 1000);
          setReviewInputs({
            name: "",
            reviewText: "",
          });
          setReviewScore(5);
        }
      });
    }
  };

  useEffect(() => {
    setAgencyReviews([]);
    setReviewInputs({
      name: "",
      reviewText: "",
    });
    setReviewScore(5);

    if (!!lang && !!type && !!id) {
      getReviews(lang, type, id).then((res) => {
        if (res.status === 200) {
          setAgencyReviews(res.data.data);
        }
      });
    }
  }, [isOpen, type, id, item]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  const showAuthPopup = () => {
    dispatch(setPopupInfo({ type: null, id: null, item: null }));
    dispatch(setAuthPopupIsOpen());
  };

  const hidePopupWrapper = (e) => {
    e.target.className === styles.popup + styles.popup__open ||
      (e.target.className === styles.popup &&
        dispatch(setPopupInfo({ type: null, id: null, item: null })));
  };

  const hidePopupBtn = () => {
    dispatch(setPopupInfo({ type: null, id: null, item: null }));
  };
  return (
    <div
      className={clsx(styles.popup, !isOpen && styles.popup__open)}
      onClick={hidePopupWrapper}
    >
      <div className={styles.container}>
        <div className={styles.close} onClick={hidePopupBtn}>
          &#10006;
        </div>
        <div className={styles.main}>
          {type === "agency" ? (
            <div className={clsx(styles.agency, "flex-between")}>
              {item?.image && <img src={item?.image} />}
              <div className={styles.right}>
                <a href={`https://${item?.website}`} target="_blank">
                  <p className={styles.title}>{item?.name}</p>
                </a>
                <p className={styles.text}>{item?.description}</p>
                <div className={styles.contacts}>
                  {/* <p>Koněvova 188/32, Praha 3</p> */}
                  {item?.phone && (
                    <a className={styles.phone} href={`tel:${item?.phone}`}>
                      +{item?.phone}
                    </a>
                  )}
                  {item?.website && (
                    <a
                      className={styles.agency__webpage}
                      href={`https://${item?.website}`}
                      target="_blank"
                    >
                      {item?.website}
                    </a>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.website}>
              <div className={clsx(styles.website__header, "flex-between")}>
                <div className={styles.rate}>
                  <span
                    className={
                      item?.position_diff > 0 ? styles.green : styles.red
                    }
                    data-rate={
                      item?.position_diff !== 0 ? item?.position_diff : ""
                    }
                  >
                    {position}
                  </span>
                </div>
                <div className={styles.header__right}>
                  <div className={clsx(styles.right__name, "flex-between")}>
                    {item?.favicon && <img src={item?.favicon} />}
                    <a href={`https://${item?.host}`} target="_blank">
                      {item?.title}
                    </a>
                  </div>
                  {/* <p className={styles.text}>{item?.description} <a className={styles.website__webpage} href={`https://${item?.host}`} target='_blank'>{item?.host}</a></p> */}
                </div>
              </div>
            </div>
          )}
          <div className={clsx(styles.reviews__login, "flex-between")}>
            {item?.reviews_count !== 0 && (
              <div className={clsx(styles.count, "flex-between")}>
                {item?.reviews_average && (
                  <RatingStars editable={false} value={item?.reviews_average} />
                )}
                <p>
                  <span>{reviews}</span> - {item?.reviews_count}
                </p>
              </div>
            )}
            <button className={styles.login__edit} onClick={showAuthPopup}>
              {edit}
            </button>
          </div>
          {agencyReviews.length !== 0 && (
            <div className={styles.reviews}>
              {agencyReviews.map((el, i) => {
                return (
                  <div className={styles.item}>
                    <div className={clsx(styles.header, "flex-between")}>
                      <div className={clsx(styles.person, "flex-between")}>
                        <div className={styles.info}>
                          <p className={styles.date}>{el?.created_at}</p>
                          <p className={styles.name}>{el?.name}</p>
                        </div>
                      </div>
                      <RatingStars
                        editable={false}
                        value={el?.score}
                        size={15}
                      />
                    </div>
                    <p className={styles.text}>{el?.body}</p>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.review__form}>
            <p className={styles.title}>{addreview}</p>
            <form
              className="flex-column"
              onSubmit={(e) => {
                e.preventDefault();
                addReviewFunc();
              }}
            >
              <input
                type="text"
                name="name"
                placeholder={name1}
                required
                onChange={onChangeInput}
                value={reviewInputs.name}
              />
              <textarea
                type="text"
                name="reviewText"
                placeholder={review}
                required
                onChange={onChangeInput}
                value={reviewInputs.reviewText}
              />
              <div className={clsx(styles.your__score, "flex-between")}>
                <p>{score}</p>
                <RatingStars
                  editable={true}
                  value={reviewScore}
                  onChange={(value) => setReviewScore(value)}
                />
              </div>
              <button type="submit">{addreview}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyPopup;
