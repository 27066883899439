import axios from 'axios';

const baseLink = "https://erotop10.com" // https://erotop10.com

// получение куки
export async function getCookies() {
    return await axios.get(`${baseLink}/sanctum/csrf-cookie`)
}

// получение списка языков
export async function getLanguages(lang) {
    return await axios.get(`${baseLink}/${lang}/spa/lang`)
}

// проверка региона
export async function checkRegion() {
    return await axios.get(`${baseLink}/spa/geo`)
}

// получение региона
export async function getRegions(lang) {
    return await axios.get(`${baseLink}/${lang}/spa/regions`)
}

// получение информации о регионе
export async function getRegionInfo(lang, slug) {
    return await axios.get(`${baseLink}/${lang}/spa/regions/${slug}`)
}

// получение списка агенств
export async function getAgencies(lang, slug, page, perPage) {
    return await axios.get(`${baseLink}/${lang}/spa/agencies?region=${slug}&page=${page}&per_page=${perPage}`)
}

// получение списка веб-сайтов
export async function getWebsites(lang, slug, page) {
    return await axios.get(`${baseLink}/${lang}/spa/sites?region=${slug}&page=${page}`)
}

// получение отзывов
export async function getReviews(lang, type, id) {
    return await axios.get(`${baseLink}/${lang}/spa/reviews?type=${type}&owner=${id}`)
}

// добавление отзыва
export async function addReview(lang, type, id, name, body, score) {
    return await axios.post(`${baseLink}/${lang}/spa/reviews`, {
        type: type,
        owner: id,
        name: name,
        body: body,
        score: score
    })
}

// отправка заявки на добавления агенства
export async function addAgencyRequest(lang, name, email, url, comment) {
    return await axios.post(`${baseLink}/${lang}/spa/orders`, {
        name: name,
        email: email,
        url: url,
        comment: comment
    })
}

// авторизация
export async function getAuth(email, password) {
    return await axios.post(`${baseLink}/signin`, {
        email: email,
        password: password,
    })
}

// проверка авторизированного пользователя
export async function checkAuth() {
    return await axios.get(`${baseLink}/user`)
}

// проверка авторизированного пользователя
export async function getAccInfo(lang) {
    return await axios.get(`${baseLink}/${lang}/spa/account`)
}

// сохранение изменённых данных в профиле
export async function saveAccInfo(lang, name, description, phone, website) {
    return await axios.post(`${baseLink}/${lang}/spa/account`, {
        name: name,
        description: description,
        phone: phone,
        website: website
    })
}

// сброс пароля
export async function restorePassword(email) {
    return await axios.post(`${baseLink}/forgot-password`, {
        email: email
    })
}
// получение методологии 
export async function getMethodology(lang) {
    return await axios.get(`${baseLink}/${lang}/spa/methodology`);
}