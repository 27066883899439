import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import clsx from 'clsx'

import { setPopupInfo } from '../../store/reviewSlice/reviewSlice'
import { getAgencies } from '../../api/requests'

import RatingStars from '../rating-stars/RatingStars'
import Loading from '../loading/Loading'

import styles from './top-agencies.module.scss'

const TopAgencies = ({ countriesInfo }) => {
    const dispatch = useDispatch()
    const { lang, country } = useParams()

    const { mainWords, selectedLanguage, selectedCountry } = useSelector(state => state.language)

    const { h22, view, reviews, morea, addreview } = mainWords

    const [agencies, setAgencies] = useState([])
    const [page, setPage] = useState(1)
    const [countPage, setCountPage] = useState(1)
    const [perPage, setPerPage] = useState(window.innerWidth < 768 ? 6 : 10)

    const [loaded, setLoaded] = useState(null)

    const getAgenciesFunc = (countryChanged) => {
        if (!!lang && !!country && page <= countPage) {
            console.log(country)
            getAgencies(lang, country, page, perPage).then(res => {
                if (res.status === 200) {
                    if (countryChanged) {
                        setCountPage(Math.ceil(res.data.count / 10))
                        setAgencies(res.data.data)
                    } else {
                        setAgencies([...agencies, ...res.data.data])
                    }
                    setTimeout(() => {
                        setLoaded(true)
                    }, 700)
                }
            })
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setPerPage(window.innerWidth < 768 ? 5 : 10);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Очистка слушателя при размонтировании компонента
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    useEffect(() => {
        setLoaded(false)
        setPage(1)
        setAgencies([])
        if (page <= countPage) {
            getAgenciesFunc(true)
        }
    }, [lang, selectedCountry])

    useEffect(() => {
        if (page <= countPage) {
            getAgenciesFunc()
        }
    }, [page])

    const showMore = () => setPage((count) => count += 1)

    const decreaseText = (text) => {
        if (window.innerWidth <= 768 && !!text) {
            return text.substring(0, 150).trimEnd() + "..."
        } else {
            return text
        }
    }

    return <div className={styles.wrapper}>
        <div className={clsx(styles.container, "flex-column")}>
            <h2>{h22} {countriesInfo.map(el => {
                if (Object.keys(el)[0] === country) {
                    return el[country][1]
                } else {
                    return
                }
            })}</h2>
            <div className={styles.agencies}>
                {loaded ? agencies.map((el, i) => {
                    return <div key={i} className={styles.agency}>
                        <div className={styles.open__reviews} onClick={(e) => {
                            e.preventDefault()
                            dispatch(setPopupInfo({ type: "agency", id: el?.id, item: el }))
                        }}>
                            {el?.image && <img src={el?.image} alt='agency-img' />}
                            <p className={styles.name}>{el?.name}</p>
                        </div>
                        <p>{decreaseText(el?.description)}</p>
                        <div className={clsx(styles.contacts, "flex-between")}>
                            {el?.phone && <a className={styles.number} href={`tel:${el?.phone}`}>+{el?.phone}</a>}
                            {el?.website && <a className={styles.webpage} href={`https://${el?.website}`} target='_blank'>{el?.website}</a>}
                        </div>
                        <div className={clsx(styles.views, "flex-between")} data-toolbar={view} onClick={() => dispatch(setPopupInfo({ type: "agency", id: el?.id, item: el }))}>
                            <div className={styles.pointer}></div>
                            {!!(el?.reviews_count) && <RatingStars editable={false} value={el?.reviews_average} />}
                            {!(el?.reviews_count === 0) ? <p>{reviews} - {el?.reviews_count}</p> : <p style={{ margin: "0" }}>{addreview}</p>}
                        </div>
                    </div>
                }) : <Loading />}
            </div>
            {!(page >= countPage) && <button className={styles.show__more_btn} onClick={showMore}>{morea}</button>}
        </div>
    </div>
}

export default TopAgencies