import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import clsx from 'clsx';

import { setCountry, setLanguage } from '../../store/languageSlice/languageSlice';
import { setAuthPopupIsOpen, setSignedIn } from '../../store/userSlice/userSlice';
import { setSentPopupIsOpen } from '../../store/reviewSlice/reviewSlice';

import { getAuth, restorePassword } from '../../api/requests';

import styles from './auth-popup.module.scss'

const AuthPopup = ({addAgencyRef}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { lang, country } = useParams();
    const { pathname } = useLocation

    const { selectedLanguage, selectedCountry, mainWords } = useSelector(state => state.language)
    const { login, to, forPartners, pass, forgot, namesa, restore, recieved, check, close, add2 } = mainWords

    const [blockState, setBlockState] = useState("auth")
    const [authValues, setAuthValues] = useState({
        email: '',
        password: ''
    })
    const [restoreEmail, setRestoreEmail] = useState("")

    // useEffect(() => {
    //     // dispatch(setLanguage(lang))
    //     // dispatch(setCountry(country))
    //     // navigate(`/${selectedLanguage}/${country}`);
    // }, [])

    const onChangeAuthValues = (e) => {
        setAuthValues({
            ...authValues,
            [e.target.name]: e.target.value
        })
    }

    const hidePopup = (e) => {
        navigate(`${lang}/${country}`)
        e.target.className === (styles.wrapper + " flex-center") && dispatch(setAuthPopupIsOpen())
    }

    const auth = () => {
        
        if (authValues.email !== "" && authValues.password !== "") {
            getAuth(authValues.email, authValues.password).then(res => {
                if (res.status === 200) {
                    dispatch(setAuthPopupIsOpen())
                    dispatch(setSignedIn(true))
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }, 10);
                    document.body.style.overflow = "visible";
                    navigate(`/${lang}/edit`)
                }
            }).catch(error => {
                if (error?.response?.status === 401) {
                    dispatch(setSentPopupIsOpen("Incorrect email or password!"))
                    document.body.style.overflow = "hidden"
                    setTimeout(() => {
                        dispatch(setSentPopupIsOpen(""))
                        document.body.style.overflow = "visible"
                    }, 1000)
                }
            })
        }
    }

    const restorePasswordFunc = () => {
        if (restoreEmail !== "") {
            restorePassword(restoreEmail).then(res => {
                if (res.status === 200) {
                    setBlockState("sent")
                }
            })
        }
    }

    const toAddAgency = (e) => {
        dispatch(setAuthPopupIsOpen())
        addAgencyRef.current?.scrollIntoView({ behavior: 'smooth' })
      }

    return <div className={clsx(styles.wrapper, "flex-center")} onClick={hidePopup}>
        <div className={clsx(styles.container, "flex-column")}>
            <form className="flex-column" onSubmit={(e) => e.preventDefault()}>
                {blockState === "auth"
                    ? <>
                        <p className={styles.title}>{to} EroTop</p>
                        <p className={styles.text}>{forPartners}</p>
                        <input value={authValues.email} type="text" name="email" placeholder="Login" onChange={onChangeAuthValues} required />
                        <input value={authValues.password} type="password" name="password" placeholder={pass} onChange={onChangeAuthValues} required />
                        <button type='submit' className={styles.submit__btn} onClick={auth}>{login}</button>
                        <button className={styles.restore__btn} onClick={() => setBlockState("restore")}>{forgot}</button>
                        <Link to={`/${selectedLanguage}/${selectedCountry}#addAgency`} className={styles.nav__add_site_link} onClick={toAddAgency}>
                            {add2}
                        </Link>
                    </>
                    : blockState === "restore"
                        ? <>
                            <p className={styles.title}>{forgot}</p>
                            <input value={restoreEmail} type="text" placeholder="E-mail" onChange={(e) => setRestoreEmail(e.target.value)} required />
                            <button type='submit' className={styles.submit__btn} onClick={() => restorePasswordFunc()}>{restore}</button>
                        </>
                        : <>
                            <p className={styles.title}>{recieved}</p>
                            <p className={styles.text}>{check}</p>
                            <button className={styles.submit__btn} onClick={() => dispatch(setAuthPopupIsOpen())}>{close}</button>
                        </>}
            </form>
        </div>
    </div>
}

export default AuthPopup