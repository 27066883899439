import ReactStars from "react-rating-stars-component";

const RatingStars = ({ editable, value, onChange = null, size = 24 }) => {
  return <ReactStars
    count={5}
    value={value}
    onChange={onChange}
    size={size}
    isHalf={true}
    activeColor="#F3C947"
    edit={editable}
  />
}

export default RatingStars