import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { addAgencyRequest, getMethodology } from "../../api/requests";
import { setSentPopupIsOpen } from "../../store/reviewSlice/reviewSlice";

import widgetSample from "../../images/widget-sample.png";

import styles from "./methodology.module.scss";
import clsx from "clsx";

const Methodology = ({ setAuthPopupIsOpen, adPortalRef }) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { lang, country } = useParams();

  const { mainWords, selectedLanguage, selectedCountry } = useSelector(
    (state) => state.language
  );

  const { yourname, comment, send, ads, yoursite } = mainWords;

  const { theme } = useSelector((state) => state.user);
  const [methodology, setMethodology] = useState({
    body: "",
    seo_description: "",
    seo_keywords: "",
    seo_title: "",
    title: "",
  });

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    url: "",
    comment: "",
  });

  const onChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const addAgencyRequestFunc = () => {
    addAgencyRequest(
      lang,
      formValues.name,
      formValues.email,
      formValues.url,
      formValues.comment
    ).then((res) => {
      if (res.status === 200) {
        dispatch(setSentPopupIsOpen(mainWords.dispatcMessage));
        document.body.style.overflow = "hidden";
        setTimeout(() => {
          dispatch(setSentPopupIsOpen(""));
          document.body.style.overflow = "visible";
        }, 2000);
        setFormValues({
          name: "",
          email: "",
          url: "",
          comment: "",
        });
      }
    });
  };

  

  useEffect(() => {
    
    const seoTitle = document.querySelector('title');
    const seoDesc = document.querySelector('meta[name="description"]');
    const seoKeyWords = document.querySelector('meta[name="keywords"]');
    getMethodology(lang).then((res) => {
      setMethodology(res.data.data);
      seoTitle.innerHTML = res.data.data.seo_title ? res.data.data.seo_title : ""
      seoDesc.content = res.data.data.seo_description ? res.data.data.seo_description : ""
      seoKeyWords.content = res.data.data.seo_keywords ? res.data.data.seo_keywords : "";
      const urlParams = window.location.hash;
      if (urlParams.includes("adInPortal")) {
        const element = document.querySelector(urlParams);

        if(element) {
          element.scrollIntoView({ behavior: 'smooth', block: "center", inline: "start" });
        }
      }
    });
  }, []);
  
  const toForm = () => {
    setAuthPopupIsOpen((prev) => !prev);
  };

  return (
    <div className={clsx("meth", theme)}>
      <div className={styles.container}>
        <h1>{methodology.title}</h1>
        <div dangerouslySetInnerHTML={{__html: methodology.body}}></div>
        <form
          id="adInPortal"
          className="flex-column"
          onSubmit={(e) => {
            e.preventDefault();
            addAgencyRequestFunc();
          }}
        >
          <input
            type="text"
            name="name"
            placeholder={`${yourname}`}
            value={formValues.name}
            onChange={onChange}
          />
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={formValues.email}
            onChange={onChange}
            required
          />
          <input
            type="text"
            name="url"
            placeholder={`${yoursite}`}
            value={formValues.url}
            onChange={onChange}
            required
          />
          <textarea
            name="comment"
            placeholder={`${comment}`}
            value={formValues.comment}
            onChange={onChange}
          ></textarea>
          <button type="submit" className={styles.submit__btn}>
            {send}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Methodology;
