import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { setAuthPopupIsOpen } from "../../store/userSlice/userSlice";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import AgencyPopup from "../agency-popup/AgencyPopup";
import SentRequestPopup from "../sent-request-popup/SentRequestPopup";

import styles from "./page.module.scss";
import AuthPopup from "../auth-popup/AuthPopup";
import {
  setCountry,
  setLanguage,
} from "../../store/languageSlice/languageSlice";
import AdultPopup from "../adult-popup/AdultPopup";
import { getRegionInfo } from "../../api/requests";

const Page = ({
  countriesInfo,
  signedIn,
  addAgencyRef,
  adultPopup,
  setAdultPopup,
  cookiesToggle,
  setCookiesToggle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lang, country, edit } = useParams();

  const { selectedLanguage, selectedCountry,  mainWords } = useSelector(
    (state) => state.language
  );
  const { authPopupIsOpen } = useSelector((state) => state.user);
  const { sentPopupIsOpen, sentPopupText } = useSelector(
    (state) => state.review
  );

  const [toUpBtn, setToUpBtn] = useState(null);
  const inCountry = countriesInfo.filter((el) => {
    let key = Object.keys(el)[0];
    if (!!country) {
      if (key === country) {
        return el;
      }
    } else {
      if (key === "europe") {
        return el;
      }
    }
  })[0][!!country ? country : "europe"];

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 100) {
        setToUpBtn(true);
      } else if (window.scrollY < 100) {
        setToUpBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (country) {
      console.log(country)
      dispatch(setCountry(country));
      if (pathname.includes("login") && !signedIn) {
        navigate(`/${selectedLanguage}/${country}/login`);
      } if (authPopupIsOpen) {
        navigate(`/${selectedLanguage}/${country}`);
        dispatch(setAuthPopupIsOpen())
      }
    }
  }, [signedIn]);

  useEffect(() => {
    if (pathname.includes("login")) {
      dispatch(setAuthPopupIsOpen());
    }
  }, [pathname]);

  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();

  const monthWord = mainWords.month[currentMonthIndex];

  useEffect(() => {
    let currentContry = country ? country : "europe";
    getRegionInfo(lang, currentContry).then((res) => {
      if (res.status === 200) {
        // document.title = "Some Title"
        // document.head.querySelector('meta[name="description"]').setAttribute('content', "Some Description")
        // document.head.querySelector('meta[name="keywords"]').setAttribute('content', "Some Keywords")
        if (!res.data.data.seo_title) {
          switch (lang) {
            case "en":
              // document.title = `EroTop – Escort Sites ${inCountry[1]} | Choose the best eurogirls for incall, outcall or escort agencies ${inCountry[1]}`;
                document.title = `Escort Sites ${inCountry[1]} – Most Popular ${monthWord} | EroTop – Escort services ${inCountry[1]}`;
              break;
            case "es":
              // document.title = `EroTop - Sitios de chicas escorts en ${inCountry[1]} | Elige a las mejores eurogirls para agencias de escorts, escorts o acompañantes ${inCountry[1]}`;
               document.title = `Escorts sitios ${inCountry[1]} – los más populares ${monthWord} | EroTop – Chicas acompañantes ${inCountry[1]}`;
              break;
            case "de":
              // document.title = `EroTop – Escortseiten ${inCountry[1]} | Wählen Sie die besten Eurogirls für Incall-, Outcall- oder Escort-Agenturen ${inCountry[1]}`;
                document.title = `Escortseiten ${inCountry[1]} inC – Die beliebtesten ${monthWord} | EroTop – Escort-Dienste  ${inCountry[1]}`;

              break;
            case "cz":
              // document.title = `EroTop – eskortní stránky ${inCountry[1]} | Vyberte si erotické privaty či eskortní agentury a ty nejlepší společnice ${inCountry[1]}`;
                document.title = `Erotické priváty a eskortní weby ${inCountry[1]} ${monthWord} | Erotop – erotické služby ${monthWord}`;
              break;
            case "ru":
              // document.title = `EroTop – Эскорт-сайты ${inCountry[1]} | Выбирайте лучших эскорту индивидуалок по вызову в ${inCountry[1]}`;
                document.title = `Эскорт-сайты ${inCountry[1]}, лучшие ${inCountry[1]} | EroTop – сайты для заказа проституток ${inCountry[1]}`;
              break;
          }
        } else {
          document.title = res.data.data.seo_title;
        }
        if (!res.data.data.seo_description) {
          switch (lang) {
            case "en":
              document.head
                .querySelector('meta[name="description"]')
                .setAttribute(
                  "content",
                  `EroTop is a prestigious rating escort websites ${inCountry[1]} and in whole the Europe. EroTop contains verified information about the legalization of prostitution ${inCountry[1]}. Choose escort sites or escort agencies ${inCountry[1]}, where you can pick up attractive prostitutes and luxury companions. Check prostitute prices ${inCountry[1]}.`
                );
              break;
            case "es":
              document.head
                .querySelector('meta[name="description"]')
                .setAttribute(
                  "content",
                  `EroTop es un prestigioso ranking de sitios web de escorts ${inCountry[1]} y en toda Europa. EroTop ofrece información verificada sobre la legalización de la prostitución ${inCountry[1]}. Elige los mejores sitios y agencias de escorts ${inCountry[1]}, donde podrás encontrar atractivas prostitutas y acompañantes de lujo. Consulta los precios de las prostitutas ${inCountry[1]}.`
                );
              break;
            case "de":
              document.head
                .querySelector('meta[name="description"]')
                .setAttribute(
                  "content",
                  `EroTop ist ein prestigeträchtiges Ranking Escort-Websites ${inCountry[1]} und in ganz Europa. EroTop bietet verifizierte Informationen zur Legalisierung der Prostitution in ${inCountry[1]}. Wählen Sie die besten Escort-Websites und -Agenturen in ${inCountry[1]}, wo Sie attraktive Prostituierte und Luxus-Escorts finden können. Überprüfen Sie die Preise für Prostituierte in ${inCountry[1]}.`
                );
              break;
            case "cz":
              document.head
                .querySelector('meta[name="description"]')
                .setAttribute(
                  "content",
                  `EroTop je prestižní hodnocení eskortních webů ${inCountry[1]} a v celé Evropě. EroTop obsahuje ověřené informace o legalizaci prostituce ${inCountry[1]}. Vyberte si eskortní weby anebo eskortní agentury ${inCountry[1]}, kde si můžete objednat atraktivní prostitutky a luxusní společnice. Zkontrolujte ceny pro prostitutky ${inCountry[1]}`
                );
              break;
            case "ru":
              document.head
                .querySelector('meta[name="description"]')
                .setAttribute(
                  "content",
                  `EroTop — это престижный рейтинг эскорт-сайтов и эскорт-агентств ${inCountry[1]}. ЭроТоп содержит проверенную информацию о легализации проституции ${inCountry[1]}. Выбирайте лучшие эскорт-сайты и эскорт-агентства ${inCountry[1]}, где вы сможете снять индивидуалок, привлекательных проституток и роскошных спутниц. Проверьте цены на проституток ${inCountry[1]}..`
                );
              break;
          }
        } else {
          document.head
            .querySelector('meta[name="description"]')
            .setAttribute("content", res.data.data.seo_description);
        }
        // document.head.querySelector('meta[name="keywords"]').setAttribute('content', res.data.data.seo_keywords)
      }
    });
  }, [lang, country]);

  useEffect(() => {
    if (authPopupIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [authPopupIsOpen]);

  useEffect(() => {
    const canonical = document.querySelector('link[rel="canonical"]');
    const baseLink = "https://erotop10.com";
    let currntLocal = window.location.hash

    if (pathname.includes("login")) {
      navigate(`/${selectedLanguage}/${selectedCountry}/login`);
      if (canonical) canonical.href = `${baseLink}/${selectedLanguage}/${selectedCountry}/login`;
    } else if (pathname.includes("meth")) {
      navigate(`/${selectedLanguage}/meth`);
      if (canonical) canonical.href = `${baseLink}/${selectedLanguage}/meth`;
    } else if (pathname.includes("edit")) {
      navigate(`/${selectedLanguage}/edit`);
      if (canonical) canonical.href = `${baseLink}/${selectedLanguage}/edit`;
    } else if (lang !== selectedLanguage) {
      navigate(`/${selectedLanguage}/${country}${currntLocal ? currntLocal : ""}`);
      if (canonical) canonical.href = `${baseLink}/${selectedLanguage}/${country}${currntLocal ? currntLocal : ""}`;
    } else if (!!country) {
      console.log(country === selectedCountry || lang === selectedLanguage)
      if (country !== selectedCountry) {
        dispatch(setCountry(country))
        return
      }
      navigate(`/${selectedLanguage}/${selectedCountry}${currntLocal ? currntLocal : ""}`);
      if (canonical) canonical.href = `${baseLink}/${selectedLanguage}/${selectedCountry}${currntLocal ? currntLocal : ""}`;
    }
}, [selectedLanguage, selectedCountry]);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // if (!signedIn && pathname.includes("/edit")) {
  if (false) {
    return (
      <div className={styles.not__found_wrapper}>
        <p className={styles.not__found}>Page not found 404</p>
      </div>
    );
  } else {
    return (
      <>
        <Header
          countriesInfo={countriesInfo}
          signedIn={signedIn}
          addAgencyRef={addAgencyRef}
        />
        <Outlet />
        <Footer countriesInfo={countriesInfo} addAgencyRef={addAgencyRef} />
        {authPopupIsOpen && <AuthPopup addAgencyRef={addAgencyRef}/>}
        <AgencyPopup />
        {adultPopup && (
          <AdultPopup
            setAdultPopup={setAdultPopup}
            cookiesToggle={cookiesToggle}
            setCookiesToggle={setCookiesToggle}
          />
        )}
        {toUpBtn && (
          <button className={styles.to__top} onClick={toTop}>
            <span>{"<"}</span>
          </button>
        )}
        {sentPopupIsOpen && <SentRequestPopup text={sentPopupText} />}
      </>
    );
  }
};

export default Page;
