import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import clsx from "clsx";

import styles from "./countries-dropdown.module.scss";

import alFlag from '../../countries-icons/al.svg'
import atFlag from '../../countries-icons/at.svg'
import beFlag from '../../countries-icons/be.svg'
import bgFlag from '../../countries-icons/bg.svg'
import chFlag from '../../countries-icons/ch.svg'
import cyFlag from '../../countries-icons/cy.svg'
import czFlag from '../../countries-icons/cz.svg'
import deFlag from '../../countries-icons/de.svg'
import esFlag from '../../countries-icons/es.svg'
import euFlag from '../../countries-icons/eu.svg'
import frFlag from '../../countries-icons/fr.svg'
import gbFlag from '../../countries-icons/gb.svg'
import grFlag from '../../countries-icons/gr.svg'
import hrFlag from '../../countries-icons/hr.svg'
import huFlag from '../../countries-icons/hu.svg'
import itFlag from '../../countries-icons/it.svg'
import luFlag from '../../countries-icons/lu.svg'
import mcFlag from '../../countries-icons/mc.svg'
import mtFlag from '../../countries-icons/mt.svg'
import nlFlag from '../../countries-icons/nl.svg'
import plFlag from '../../countries-icons/pl.svg'
import ptFlag from '../../countries-icons/pt.svg'
import roFlag from '../../countries-icons/ro.svg'
import rsFlag from '../../countries-icons/rs.svg'
import skFlag from '../../countries-icons/sk.svg'
import trFlag from '../../countries-icons/tr.svg'

const countryImages = {
  albania: alFlag,
  austria: atFlag,
  belgium: beFlag,
  bulgaria: bgFlag,
  switzerland: chFlag,
  cyprus: cyFlag,
  czechia: czFlag,
  germany: deFlag,
  spain: esFlag,
  europe: euFlag,
  france: frFlag,
  "great-britain": gbFlag,
  greece: grFlag,
  croatia: hrFlag,
  hungary: huFlag,
  italy: itFlag,
  luxembourg: luFlag,
  monaco: mcFlag,
  malta: mtFlag,
  netherlands: nlFlag,
  poland: plFlag,
  portugal: ptFlag,
  romania: roFlag,
  serbia: rsFlag,
  slovakia: skFlag,
  turkiye: trFlag,
}

const CountriesDropdown = ({ selectCountry, countriesInfo }) => {
  const [open, setOpen] = useState(null);
  const { lang, country } = useParams()

  const { selectedCountry, selectedLanguage } = useSelector(
    (state) => state.language
  );

  const select = (countryName) => {
    selectCountry(countryName);
    setOpen(false);
  };

  return (
    <div className={styles.dropdown}>
      <div
        className={clsx(styles.select, "flex-between")}
        onClick={() => setOpen((prev) => !prev)}
      >
        <img src={countryImages[selectedCountry]} />
        <p>{countriesInfo.map(el => {
          let key = Object.keys(el)[0]
          if (key === country) {
            return el[key][0]
          } else {
            return
          }
        })}</p>
      </div>
      {open && (
        <div className={styles.options}>
          {countriesInfo.map((el, i) => {
            let key = Object.keys(el)[0]
            if (key === selectedCountry) {
              return
            } else {
              return (
                <Link
                  to={`/${lang}/${key}`}
                  className={clsx(styles.option, "flex-between")}
                  onClick={() => select(key)}
                >
                  <img src={countryImages[key]} alt="flag" />
                  <p>{el[key][0]}</p>
                </Link>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default CountriesDropdown;
